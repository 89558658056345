import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ContentService} from "../../services/content.service";
import {Content} from "../../models/content.model";

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent {

  @Input() trainingId: string = '';
  @Input() chapterId: string = '';
  @Input() userVideo: string = '';
  private _contentOrderNo: number = 1;

  @Input() set contentOrderNo(value: number) {
    this._contentOrderNo = value;
    this.getContent()
  }

  @Output() receivedContent: EventEmitter<any> = new EventEmitter<any>();
  @Output() disableNextButton: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() quizCompleted: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() timeTrackingChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  get contentOrderNo(): number {
    return this._contentOrderNo;
  }

  content: Content | null = null;

  constructor(
    private contentService: ContentService
  ) {
  }

  ngOnInit(): void {
    this.getContent();
  }

  getContent() {
    this.contentService.getContents(this.trainingId, this.chapterId, this.contentOrderNo || 1)
      .then(contentsSnap => {
      const contents = contentsSnap.docs.map(contentSnap => contentSnap.data());
      this.content = contents[0];
      if (this.content.type === 'quiz') {
        this.disableNextButton.emit(true);
      }
      this.receivedContent.emit({
        id: this.content.id,
        type: this.content.type
      });
    });
  }

  onQuizCompleted(completed: boolean) {
    this.quizCompleted.emit(completed);
  }

  onTimeTrackingChanged(changed: any) {
    this.timeTrackingChanged.emit(changed);
  }

}
