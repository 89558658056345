import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { FloatLabelType } from '@angular/material/form-field';
import { Router } from '@angular/router';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent {
  hideRequiredControl = new FormControl(false);
  floatLabelControl = new FormControl('auto' as FloatLabelType);
  options = this._formBuilder.group({
    hideRequired: this.hideRequiredControl,
    floatLabel: this.floatLabelControl,
  });

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  passwordFormControl: FormControl<any> = new FormControl('', [
    Validators.required,
    Validators.minLength(6),
  ]);

  hide = true;
  errorMessage: string | undefined;

  accountErrorMessage: string | undefined;

  constructor(
    public auth_service: AuthenticationService,
    public auth: AngularFireAuth,
    private _formBuilder: FormBuilder,
    private router: Router,
    private translate: TranslateService
  ) { }

  getErrorMessage(): string {
    if (this.emailFormControl.hasError('required')) {
      return this.translate.instant('e-learning_training_required_error');
    }

    return this.emailFormControl.hasError('email') ? this.translate.instant('e-learning_training_invalid_mail_error') : '';
  }

  register() {
    this.auth_service
      .signUp(this.emailFormControl.value!, this.passwordFormControl.value)
      .then((res) => {
        this.auth.updateCurrentUser(res.user).then(r => {
          this.router.navigate(['/trainings']).then();
        });
      })
      .catch((error) => {
        console.error(error.code, error.message);
        switch (error.code) {
          case 'auth/email-already-in-use': {
            this.accountErrorMessage = this.translate.instant('e-learning_training_registration_mail_exists');
            break;
          }
          default: {
            this.accountErrorMessage = 'Unexpected Error';
            break;
          }
        }
      });
  }
}
