import { Component, Input } from '@angular/core';
import {Content} from "../../models/content.model";

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent {
  @Input() content!: Content;

}
