<div
  class="sidenav-content-container"
  [class.sidenav-is-mobile]="mobileQuery.matches"
>
  <mat-sidenav-container
    class="sidenav-container"
    [hasBackdrop]="mobileQuery.matches"
  >
    <mat-sidenav
      #sidenav
      [(opened)]="!mobileQuery.matches && opened"
      [mode]="mobileQuery.matches ? 'over' : 'side'"
      [fixedInViewport]="mobileQuery.matches"
      [class]="(userInfo?.isAdmin && 'admin') || 'trainer'"
    >
      <img
        class="p-4 mb-6"
        src="../../../../assets/img/SBS__LOGO__ELearning_weiss-568.png" />
      <mat-nav-list class="text-center">
        <div class="sidenav flex flex-col align-center">
          <h1 class="leading-tight text-5xl text-black text-left pl-2">
            {{ pageTitle }}
          </h1>
        </div>

        <div *ngFor="let link of sidenavLinks">
          <app-nav-elements
            [link]="link.link"
            [icon]="link.icon"
            [name]="link.name"
            [isAdmin]="userInfo?.isAdmin"
            [exact]="link.exact"
          ></app-nav-elements>
        </div>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
      <button
        mat-icon-button
        (click)="sidenav.toggle()"
        class="burger-menu-button"
      >
        <mat-icon [class]="(userInfo?.isAdmin && 'admin-text') || 'trainer-text'">
          menu
        </mat-icon>
      </button>
      <div class="p-10 mt-16">
        <app-top-nav></app-top-nav>
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
