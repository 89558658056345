import {Component, Input} from '@angular/core';
import {Training} from "../../models/training.model";

@Component({
  selector: 'app-training-box',
  templateUrl: './training-box.component.html',
  styleUrls: ['./training-box.component.scss']
})
export class TrainingBoxComponent {

  @Input() training!: Training;

}
