<div class="mt-10 sm:mt-0">
  <div class="md:grid md:grid-cols-3 md:gap-6">
    <div class="md:col-span-1">
      <div class="px-4 sm:px-0">
        <h3 class="text-lg font-medium leading-6 text-gray-900">
          Personal Information
        </h3>
        <p class="mt-1 text-sm text-gray-600">
          Update you personal information.
        </p>
      </div>
    </div>
    <div class="mt-5 md:col-span-2 md:mt-0">
      <form [formGroup]="userInfoFormControl">
        <div class="overflow-hidden shadow sm:rounded-md">
          <div class="bg-white px-4 py-5 sm:p-6">
            <div class="grid grid-cols-6 gap-6">
              <div class="col-span-6 sm:col-span-6">
                <label class="block w-32 text-sm font-medium text-gray-700"
                  >Photo</label
                >
                <div class="mt-1 flex flex-col md:flex-row items-center">
                  <img
                    [src]="
                      photoURL ??
                      'https://ui-avatars.com/api/?name=' + initialNameLetters
                    "
                    class="rounded-full w-36 md:w-24 md:mx-0"
                    alt="Avatar"
                  />
                  <!-- </span> -->
                  <button
                    type="button"
                    class="ml-5 mt-5 md:mt-0 text-center rounded-md border border-gray-300 bg-black bg-opacity-80 py-2 px-3 text-sm font-medium leading-4 text-white shadow-sm hover:bg-black focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2"
                    (click)="fileInput.click()"
                  >
                    Change
                  </button>

                  <input
                    #fileInput
                    type="file"
                    class=""
                    value="Change"
                    hidden
                    (change)="uploadImage($event, userInfo)"
                  />
                </div>
              </div>
              <div class="col-span-6 sm:col-span-3">
                <label
                  for="first-name"
                  class="block text-sm font-medium text-gray-700"
                  >First name</label
                >
                <input
                  type="text"
                  name="first-name"
                  id="first-name"
                  [placeholder]="userInfo.firstName"
                  autocomplete="first-name"
                  formControlName="firstName"
                  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-black focus:ring-black sm:text-sm"
                />
              </div>

              <div class="col-span-6 sm:col-span-3">
                <label
                  for="last-name"
                  class="block text-sm font-medium text-gray-700"
                  >Last name</label
                >
                <input
                  type="text"
                  name="last-name"
                  id="last-name"
                  [placeholder]="userInfo.lastName"
                  autocomplete="family-name"
                  formControlName="lastName"
                  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-black focus:ring-black sm:text-sm"
                />
              </div>

              <div class="col-span-6 lg:col-span-3">
                <label
                  for="email-address"
                  class="block text-sm font-medium text-gray-700"
                  >Email address</label
                >
                <input
                  type="text"
                  name="email-address"
                  id="email-address"
                  [placeholder]="userInfo.email"
                  autocomplete="email"
                  formControlName="email"
                  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-black focus:ring-black sm:text-sm"
                />
              </div>

              <!-- Address information if needed. -->
              <!-- <div class="col-span-6 lg:col-span-4">
                <label
                  for="street-address"
                  class="block text-sm font-medium text-gray-700"
                  >Street address</label
                >
                <input
                  type="text"
                  name="street-address"
                  id="street-address"
                  autocomplete="street-address"
                  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>

              <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                <label
                  for="city"
                  class="block text-sm font-medium text-gray-700"
                  >City</label
                >
                <input
                  type="text"
                  name="city"
                  id="city"
                  autocomplete="address-level2"
                  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>

              <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                <label
                  for="region"
                  class="block text-sm font-medium text-gray-700"
                  >State / Province</label
                >
                <input
                  type="text"
                  name="region"
                  id="region"
                  autocomplete="address-level1"
                  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>

              <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                <label
                  for="postal-code"
                  class="block text-sm font-medium text-gray-700"
                  >ZIP / Postal code</label
                >
                <input
                  type="text"
                  name="postal-code"
                  id="postal-code"
                  autocomplete="postal-code"
                  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div> -->
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
