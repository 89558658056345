import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-step-connector',
  templateUrl: './step-connector.component.html',
  styleUrls: ['./step-connector.component.scss']
})
export class StepConnectorComponent {
  @Input() active!: boolean;
  @Input() completed!: boolean;

}
