import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { ImageComponent } from './components/image/image.component';
import { VideoComponent } from './components/video/video.component';
import { TextComponent } from './components/text/text.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ContentComponent } from './pages/content/content.component';
import {MatListModule} from "@angular/material/list";
import {RouterLink} from "@angular/router";
import { ViewComponent } from './components/view/view.component';
import {QuizzesModule} from "../quizzes/quizzes.module";

@NgModule({
  declarations: [
    ImageComponent,
    VideoComponent,
    TextComponent,
    ContentComponent,
    ViewComponent,
  ],
  exports: [
    ContentComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    SharedModule,
    MatListModule,
    RouterLink,
    QuizzesModule
  ]
})
export class ContentModule { }
