import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import { QuizService } from '../services/quiz.service';
import {Quiz} from "../models/quiz.model";
import {QuizResult} from "../models/quiz-result.model";
import {UserTrainingService} from "../../../shared/services/user-training.service";
import {UserTraining} from "../../../shared/models/user-training.model";


@Component({
  selector: 'app-quizzes',
  templateUrl: './quizzes.component.html',
  styleUrls: ['./quizzes.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuizzesComponent {

  @Input() trainingId!: string;
  @Input() chapterId!: string;
  @Input() contentId!: string;

  @Output() disableNextButton: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() quizCompleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  quizzes: Quiz[] = [];
  isExam: boolean = false;
  isExamCompleted: boolean = false;
  loadingQuiz: boolean = true;
  loadingResults: boolean = false;
  tries: number = 0;

  isQuizCompleted: boolean = false;

  questions: any = [];
  options: any;
  userTraining: UserTraining | undefined;

  constructor(
    private quizService: QuizService,
    private userTrainingService: UserTrainingService
  ) { }

  ngOnInit() {
    this.getQuizzes();
  }

  getQuizzes() {
    this.quizService.getQuizzes(this.trainingId, this.chapterId, this.contentId)
      .then((quizzes: Quiz[]) =>  {
        this.isExam = quizzes.some((quiz: Quiz) => quiz.exam);
        this.checkExistingUserTraining(quizzes).then(() => {
          this.loadingQuiz = false;
        });
      });
  }

  // check if there are existing user options and match them the to question options
  async checkExistingUserTraining(quizzes: any) {
    const userTraining = await this.userTrainingService.getUserTraining(this.trainingId);
    this.userTraining = userTraining;
    this.isExamCompleted = userTraining?.exam || false;
    if (userTraining && userTraining!.examResults) {
      quizzes.forEach((quiz: any) => {
        const userQuestion = userTraining.examResults!.find((uq: any) => uq.id === quiz.id);
        if (userQuestion) {
          quiz.options.forEach((option: any) => {
            const userOption = userQuestion.options.find((uo: any) => uo.id === option.id);
            if (userOption) {
              option.selected = userOption.selected;
            }
          });
        }
      });
    }
    this.quizzes = quizzes;
  }

  onDragChange(quiz: Quiz) {
    console.log(quiz);
    this.storeResult();
  }

  onFillChange(quiz: Quiz) {
    console.log(quiz);
    this.storeResult();
  }

  onSingleChange(quiz: Quiz) {
    console.log(quiz);
    this.storeResult();
  }

  onMultiChange(quiz: Quiz) {
    console.log(quiz);
    this.storeResult();
  }

  storeResult() {
    if (this.isExam) {
      this.prepareResults();
      this.userTrainingService.saveExamResult(this.trainingId, this.chapterId, this.contentId, this.quizzes)
        .then();
    }
  }

  send() {
    this.loadingResults = true;
    this.prepareResults();
    this.quizService.getQuizResult(this.trainingId, this.chapterId, this.contentId, this.quizzes)
      .then((quizResult: QuizResult) =>  {
        this.tries = this.tries + 1;
        this.quizzes = quizResult.results;
        this.isQuizCompleted = true;
        this.quizCompleted.emit(true);
        this.loadingResults = false;
      });
  }

  finish() {
    this.loadingResults = true;
    this.prepareResults();
    this.quizService.getQuizResult(this.trainingId, this.chapterId, this.contentId, this.quizzes)
      .then((quizResult: QuizResult) =>  {
        this.quizzes = quizResult.results;
        this.isQuizCompleted = true;
        this.quizCompleted.emit(true);
        this.loadingResults = false;
      });
  }

  prepareResults() {
    const selections: any[] = [];
    this.quizzes.forEach((quiz: Quiz) => {
      const selection: any = {
        id: quiz.id,
        type: quiz.type,
        selections: []
      };
      if (quiz.type === 'drag') {
        selection.selections = quiz.left;
        selections.push(selection);
      } else {
        selection.selections = quiz.options.filter((option: any) => option.selected);
        selections.push(selection);
      }
    });
  }

  downloadCertificate() {

  }

}
