<div *ngIf="!correct && tries == 1"  class="text-red-700 pl-8 mt-8">
  {{'e-learning_quiz_not_correct_hint' | translate}}
</div>
<mat-selection-list
  class="quiz-option !m-6"
  [(ngModel)]="selectedOptions"
  (ngModelChange)="changeHandler($event)"
>
  <mat-list-option
    color="primary"
    *ngFor="let option of options"
    [value]="option"
    [class.error]="isQuizCompleted && !option.result && tries > 1"
    [class.success]="isQuizCompleted && option.result && (tries > 1 || correct)"
    [disabled]="disabled"
  >
    <p class="option-text">
      <span class="text-lg font-medium">{{ option?.answer | ct }}</span>
    </p>
  </mat-list-option>
</mat-selection-list>
