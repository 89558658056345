import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TrainingChapter} from "../../models/training-chapter.model";
import {Training} from "../../models/training.model";
import {UserTrainingService} from "../../../../shared/services/user-training.service";
import {Router} from "@angular/router";
import {UserTraining} from "../../../../shared/models/user-training.model";

@Component({
  selector: 'app-list-chapter',
  templateUrl: './list-chapter.component.html',
  styleUrls: ['./list-chapter.component.scss']
})
export class ListChapterComponent implements OnInit {

  @Input() training!: Training;
  @Output() firstChapterId: EventEmitter<string> = new EventEmitter<string>();
  chapters: TrainingChapter[] = [];
  userTraining: UserTraining | null = null;

  constructor(
    private userTrainingService: UserTrainingService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    let chapters = this.training.details?.chapters || [];
    if (chapters.length > 0) {
      chapters = chapters.sort((a, b) => (a.order > b.order) ? 1 : -1);
    }
    this.checkExistingUserTrainings(chapters);
  }

  checkExistingUserTrainings(chapters: TrainingChapter[]) {
    this.userTrainingService.getUserTraining(this.training.id!).then(userTraining => {
      if (userTraining) {
        this.userTraining = userTraining;
        this.chapters = chapters.map(chapter => {
          const userChapter = userTraining.chapters.find(uc => uc.chapterId === chapter.id);
          if (userChapter) {
            chapter.started = true;
            // chapter.completed = userChapter.started;
            chapter.completedPercents = userChapter.completedPercents;
          } else {
            chapter.started = false;
            chapter.completed = false;
            chapter.completedPercents = 0;
          }
          return chapter;
        });
      }
      this.chapters = chapters;
      const first = this.chapters.find(chapter => chapter.order === 1)?.id;
      this.firstChapterId.emit(first || '');
    });
  }

  navigateTo(chapter: TrainingChapter) {
    if ((chapter.started || chapter.order === 1) && this.userTraining) {
      this.router.navigate(['trainings', this.training.id, 'chapter', chapter.id, 'content']).then();
    }
  }

  downloadCertificate() {

  }
}
