<div class="chapter-container w-full max-w-screen-lg">
  <div *ngIf="contentOrderNo === 1" class="title-section mb-10">
    <h1 class="text-3xl font-bold mb-3">{{ chapter?.title | ct }}</h1>
    <div>
      <img
        *ngIf="chapter?.image.de"
        class="object-cover pb-3 w-full max-h-96"
        [src]="chapter?.image | ct"
        alt="Chapter"
      />
    </div>
    {{ chapter?.description | ct }}
  </div>
  <div *ngIf="contentOrderNo !== 1" class="title-section">
    <h1 class="text-md text-gray-600 font-bold">{{ chapter?.title | ct }}</h1>
  </div>

  <app-content
    *ngIf="loadedChapter"
    [trainingId]="trainingId"
    [chapterId]="chapterId"
    [contentOrderNo]="contentOrderNo"
    [userVideo]="userVideo"
    (receivedContent)="onReceivedContent($event)"
    (disableNextButton)="onDisableNextButton($event)"
    (quizCompleted)="onQuizCompleted($event)"
    (timeTrackingChanged)="onTimeTrackingChanged($event)"
  ></app-content>

  <br />
  <div *ngIf="!hideButtonRow" class="bg-gray-50 text-right mt-4">
    <button *ngIf="contentOrderNo > 1 || previousChapter"
      mat-raised-button
      (click)="previous()"
    >
      {{ 'e-learning_previous' | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      class="ml-3 min-w-[170px]"
      *ngIf="!chapter?.exam"
      [disabled]="(contentType === 'video' && !completedContent) || (disableNextButton && !completedContent) || (timePercent < 100 && !completedContent)"
      (click)="next()"
    >
      <mat-progress-spinner
        *ngIf="timePercent < 100 && !completedContent"
        [color]="'warn'"
        [mode]="'determinate'"
        [diameter]="30"
        [value]="timePercent">
      </mat-progress-spinner>
      <span *ngIf="(contentType !== 'video' && timePercent >= 100) || completedContent">{{ 'e-learning_next' | translate }}</span>
    </button>
  </div>
</div>
