import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { switchMap } from 'rxjs';
import { User } from '../../models/user.model';
import { ImageUploadService } from '../../service/image-upload.service';
import { UserService } from '../../service/user.service';

@Component({
  selector: 'app-personal-info-form',
  templateUrl: './personal-info-form.component.html',
  styleUrls: ['./personal-info-form.component.scss']
})
export class PersonalInfoFormComponent {
  @Input() userInfo: User = {} as User;
  @Input() photoURL: string | undefined;
  @Input() imagePlaceHolder!: string;

  @Output() private onFormGroupChange = new EventEmitter<any>();

  initialNameLetters: string = '';
  uploadImageSub: any;

  userInfoFormControl: FormGroup = new FormGroup({
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    email: new FormControl(''),
  });

  constructor(
    private imageUploadService: ImageUploadService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.initialNameLetters = this.userInfo.firstName.charAt(0) + this.userInfo.lastName.charAt(0);
    this.imagePlaceHolder = this.imagePlaceHolder + this.initialNameLetters;

    this.onFormGroupChange.emit(this.userInfoFormControl);
  }

  ngOnDestroy(): void {
    if (this.uploadImageSub) {
      this.uploadImageSub.unsubscribe();
    }
  }

  uploadImage(event: any, user: User) {
    this.uploadImageSub = this.imageUploadService
      .uploadImage(event.target.files[0], `profiles/${ user.uid }/profile-image`)
      .pipe(
        switchMap(async (photoURL: string) => {
          await this.userService.updateUser({ ...user, photoURL });
          this.photoURL = photoURL;
        })
      )
      .subscribe();
  }
}
