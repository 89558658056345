<div *ngIf="dataSource.data && dataSource.data.length > 0" class="flex flex-col ml-auto">
  <h2 class="text-3xl">
    {{ (isAdmin ? 'e-learning_training_dashboard_lead_overview' :
      'e-learning_training_dashboard_overview') | translate }}
  </h2>
  <div class="training-table-container text-sm my-4 border-black border-2">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      class="mat-elevation-z8"
      [class]="isAdmin ? 'admin-table-header' : 'trainer-table-header'"
    >
      <!-- Date Column -->
      <ng-container *ngIf="!isAdmin" matColumnDef="date">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by date"
        >
          {{ 'e-learning_training_dashboard_start_date' | translate}}
        </th>
        <td mat-cell *matCellDef="let dateRow" class="font-medium">
          {{ dateRow.startedAt | date : "dd.MM.yyyy" }}
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container *ngIf="isAdmin" matColumnDef="name">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by name"
        >
          {{ 'e-learning_training_dashboard_user_name' | translate}}
        </th>
        <td mat-cell *matCellDef="let nameRow" class="font-medium">
          {{ nameRow.name }}
        </td>
      </ng-container>

      <!-- Training Column -->
      <ng-container matColumnDef="training">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by training"
        >
          {{ 'e-learning_training_dashboard_name' | translate}}
        </th>
        <td mat-cell
            [routerLink]="'/trainings/' + trainingRow.trainingId"
            *matCellDef="let trainingRow" class="font-medium">
          {{ trainingRow.trainingTitle | ct }}
        </td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="status" *ngIf="!isAdmin; else admin">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by status"
        >
          {{ 'e-learning_training_dashboard_status' | translate}}
        </th>
        <td mat-cell *matCellDef="let statusRow" class="font-medium">
          <span
            [class]="
              statusRow.completed
                ? 'text-green-600'
                : statusRow.isExpired
                ? 'text-red-600'
                : 'text-blue-800'
            "
          >
            {{ (statusRow.completed ? 'e-learning_training_completed' : 'e-learning_training_in_progress') | translate}}
          </span>
        </td>
      </ng-container>

      <!-- Admin Status Column -->
      <ng-template #admin>
        <ng-container matColumnDef="status">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            sortActionDescription="Sort by status"
          >
            Status
          </th>

          <td mat-cell *matCellDef="let statusRow" class="font-medium">
            <span [class]="'text-' + statusRow.statusColor + '-600'"
              >{{ statusRow.status }}%</span
            >
            <div class="inline" *ngIf="statusRow.statusColor === 'red'">
              <p class="text-red-600 inline mx-5">Failed</p>
              <mat-icon class="align-bottom material-icons-outlined">warning</mat-icon>
            </div>
          </td>
        </ng-container>
      </ng-template>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      class="border-t"
      [pageSizeOptions]="[5, 10, 25, 100]"
      aria-label="Trainings per page"
    ></mat-paginator>
  </div>
</div>
