import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { FloatLabelType } from '@angular/material/form-field';
import { Router } from '@angular/router';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  hideRequiredControl = new FormControl(false);
  floatLabelControl = new FormControl('auto' as FloatLabelType);
  options = this._formBuilder.group({
    hideRequired: this.hideRequiredControl,
    floatLabel: this.floatLabelControl,
  });

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  passwordFormControl: FormControl<any> = new FormControl('', [
    Validators.required,
    Validators.minLength(6),
  ]);

  hide = true;
  errorMessage: string | undefined;

  accountErrorMessage: string | undefined;

  constructor(
    public auth_service: AuthenticationService,
    public auth: AngularFireAuth,
    private _formBuilder: FormBuilder,
    private router: Router,
    private translate: TranslateService
  ) { }

  getErrorMessage(): string {
    if (this.emailFormControl.hasError('required')) {
      return this.translate.instant('e-learning_training_required_error');
    }

    return this.emailFormControl.hasError('email') ? this.translate.instant('e-learning_training_invalid_mail_error') : '';
  }

  login() {
    this.auth_service
      .signIn(this.emailFormControl.value!, this.passwordFormControl.value)
      .then((res) => {
        this.auth.updateCurrentUser(res.user);
        this.router.navigate(['/profile']);
      })
      .catch((error) => {
        switch (error.code) {
          case 'auth/invalid-email':
          case 'auth/wrong-password':
          case 'auth/user-not-found': {
            this.accountErrorMessage = this.translate.instant('e-learning_training_login_error');
            break;
          }
          default: {
            this.accountErrorMessage = 'Unexpected Error';
            break;
          }
        }
      });
  }

}
