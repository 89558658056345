import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { MatPaginator } from '@angular/material/paginator';
import {UserTrainingService} from "../../../../shared/services/user-training.service";

export interface PeriodicElement {
  name?: string;
  date?: Date;
  training: string;
  status: string;
}

const trainerSampleData: PeriodicElement[] = [
  {
    date: new Date('2022-02-14'),
    training: 'Training A',
    status: '14.02.2023',
  },
  {
    date: new Date('2016-08-03'),
    training: 'Training XY',
    status: 'completed',
  },
  {
    date: new Date('2016-08-03'),
    training: 'Training XYZ',
    status: '03.08.2017',
  },
  {
    date: new Date('2022-02-14'),
    training: 'Training A',
    status: '14.02.2023',
  },
  {
    date: new Date('2016-08-03'),
    training: 'Training XY',
    status: 'completed',
  },
  {
    date: new Date('2016-08-03'),
    training: 'Training XYZ',
    status: '03.08.2017',
  },
];


const adminSampleData: PeriodicElement[] = [
  {
    name: 'Fahrer A',
    training: 'Training A',
    status: '95',
  },
  {
    name: 'Fahrer B',
    training: 'Training Some Course',
    status: '50',
  },
  {
    name: 'Fahrer C',
    training: 'Training XYZ',
    status: '75',
  },
  {
    name: 'Fahrer D',
    training: 'Training A',
    status: '40',
  },
  {
    name: 'Fahrer E',
    training: 'Training XY',
    status: '100',
  },
  {
    name: 'Fahrer F',
    training: 'Training XYZ',
    status: '60',
  },
];

@Component({
  selector: 'app-certificate-table',
  templateUrl: './certificate-table.component.html',
  styleUrls: ['./certificate-table.component.scss'],
})

export class CertificateTableComponent implements OnInit, AfterViewInit {
  @Input() isAdmin!: boolean | null;
  @Input() userInfo!: any;

  @ViewChild(MatSort) sort: MatSort = new MatSort();
  @ViewChild(MatPaginator) paginator!: MatPaginator;


  trainerData = trainerSampleData.map((item) => {
    return {
      ...item,
      isExpired: this.checkTrainingExpired(item.status),
    };
  });

  adminData = adminSampleData.map((item) => {
    return {
      ...item,
      statusColor: this.checkTrainerTrainingStatus(item.status),
    };
  });

  displayedColumns: string[] = ['date', 'training', 'status'];
  dataSource = new MatTableDataSource();

  constructor(
    private userTrainingService: UserTrainingService
  ) {
  }

  ngOnInit(): void {
    if (this.isAdmin) {
      this.displayedColumns = ['name', 'training', 'status'];
      this.dataSource.data = this.adminData;
    } else {
      this.userTrainingService.getUserTrainings().then((data) => {
        this.dataSource.data = data;
      });
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  checkTrainingExpired(date: string) {
    const currentDate = new Date();
    const trainingDate = new Date(date);
    return currentDate > trainingDate;
  }

  checkTrainerTrainingStatus(status: string) {
    const nStatus = Number(status);
    if (nStatus > 50) {
      return 'green';
    }
    else return 'red';
  }

}
