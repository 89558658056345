import {Component, OnDestroy, OnInit} from '@angular/core';
import {Training} from "../../models/training.model";
import {TrainingService} from "../../services/training.service";
import {ActivatedRoute} from "@angular/router";
import {UserTrainingService} from "../../../../shared/services/user-training.service";

@Component({
  selector: 'app-training-view',
  templateUrl: './training-view.component.html',
  styleUrls: ['./training-view.component.scss']
})
export class TrainingViewComponent implements OnInit, OnDestroy {

  training: Training | null = null;
  trainingId: string = '';
  chapterId: string = '';
  trainingCompleted: boolean = false;
  sub: any;

  constructor(
    private trainingService: TrainingService,
    private userTrainingService: UserTrainingService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe((params: any) => {
      this.trainingId = <string>params['id'];
      this.chapterId = <string>params['chapterId'];
      this.getTraining();
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  getTraining() {
    if (!this.training) {
      this.trainingService.getTraining(this.trainingId).then(trainingSnap => {
        const training = trainingSnap.data()!;
        this.setTrainingStatus(training);
      });
    }
  }

  onChapterChanged(chapterChanged: boolean) {
    if (chapterChanged) {
      this.setTrainingStatus(this.training!);
    }
  }

  setTrainingStatus(training: Training) {
    if (training) {
      let trainingCompleted = false;
      this.userTrainingService.getUserTraining(this.trainingId).then(userTraining => {
        training.details?.chapters.forEach(chapter => {
          if (this.chapterId && chapter.id === this.chapterId) {
            chapter.active = true;
          } else {
            chapter.active = false;
          }
          if (userTraining) {
            trainingCompleted = userTraining?.completed;
            userTraining.chapters.forEach(userChapter => {
              if (userChapter.chapterId === chapter.id) {
                chapter.completed = userChapter.completed;
              }
            });
          }
        });
        this.training = training || null;
        this.trainingCompleted = trainingCompleted;
      });
    }
  }
}
