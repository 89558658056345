import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AuthenticationService } from '../../../services/auth/authentication.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {

  constructor(
    private authService: AuthenticationService,
  ) { }

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  success: string | undefined;
  error: string | undefined;

  getErrorMessage(): string {
    if (this.emailFormControl.hasError('required')) {
      return 'You must enter a value';
    }

    return this.emailFormControl.hasError('email') ? 'Not a valid email' : '';
  }

  closeModal() {
    this.success = undefined;
    this.error = undefined;
  }

  forgotPassword() {
    if (this.emailFormControl.valid) {
      this.authService
        .forgotPassword(this.emailFormControl.value as string)
        .then(() => {
          this.success = 'Password reset email sent, check your inbox.';
        })
        .catch((error) => {
          switch (error.code) {
            case 'auth/invalid-email':
            case 'auth/user-not-found': {
              this.error = 'Email not found!';
              break;
            }
            default: {
              this.error = 'Unexpected Error';
              break;
            }
          }
        });
    }
  }
}
