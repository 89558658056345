import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RomanNumeralPipe } from './pipes/roman-numeral.pipe';
import {ContentTranslatePipe} from "./pipes/content-translate.pipe";
import { SafePipe } from './pipes/safe.pipe';



@NgModule({
  declarations: [
    RomanNumeralPipe,
    ContentTranslatePipe,
    SafePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    RomanNumeralPipe,
    ContentTranslatePipe,
    SafePipe
  ],
  providers:
  [
    ContentTranslatePipe
  ]
})
export class SharedModule { }
