import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'romanNumeral'
})
export class RomanNumeralPipe implements PipeTransform {
  intToRoman = (num: any) => {
    const map: any = {
      M: 1000,
      CM: 900,
      D: 500,
      CD: 400,
      C: 100,
      XC: 90,
      L: 50,
      XL: 40,
      X: 10,
      IX: 9,
      V: 5,
      IV: 4,
      I: 1,
    };

    let result = '';

    for (const key in map) {
      const repeatCounter = Math.floor(num / map[key]);

      if (repeatCounter !== 0) {
        result += key.repeat(repeatCounter);
      }

      num %= map[key];

      if (num === 0) return result;
    }

    return result;
  };

  transform(value: any, ...args: unknown[]): unknown {

    return this.intToRoman(value);
  }

}
