import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map } from 'rxjs/operators';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    public fs: AngularFirestore,
    private afAuth: AngularFireAuth,
    private afStore: AngularFirestore
  ) { }

  signIn(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  signOut() {
    return this.afAuth.signOut();
  }

  signUp(email: string, password: string) {
    return this.afAuth.createUserWithEmailAndPassword(email, password);
  }

  getCurrentUser() {
    return this.afAuth.user;
  }

  getCurrentUserPromise(): Promise<firebase.User | null> {
    return new Promise((resolve) => {
      this.afAuth.user.subscribe(user => {
        resolve(user);
      });
    });
  }

  getUserData(uid: string) {
    return this.afStore.collection('users').doc(uid).valueChanges();
  }

  isAuthenticated() {
    return this.afAuth.user.pipe(map(user => user !== null));
  }

  forgotPassword(email: string) {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  getToken() {
    return this.afAuth.idToken;
  }
}
