<div class="container h-full"
     [routerLink]="'/trainings/' + training.id">
  <div
    class="card cursor-pointer border-2 border-black hover:shadow-md
    hover:border-opacity-75 transform hover:-translate-y-1 transition-all
     duration-200 antialiased h-full"
  >
    <div class="h-44 bg-gray-200">
      <img *ngIf="training.image && training.image.de"
           class="object-cover h-full w-full"
           [src]="training.image | ct" alt="Training"/>
      <img *ngIf="!training.image.de"
           class="object-contain h-full w-full p-8"
           src="assets/img/SBS__LOGO__ELearning.png" alt="SBS"/>
    </div>
    <div class="h-44 m-3">
      <h2 class="text-lg mb-2">
        {{ training.title | ct }}
      </h2>

      <p class="text-gray-700 text-base mb-4">
        {{ training.intro | ct }}
      </p>
    </div>
  </div>
</div>
