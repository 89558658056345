<button mat-button
        class="mb-2"
        [routerLink]="'/trainings'">
  <mat-icon>chevron_left</mat-icon>
  {{ 'e-learning_back_to_training_overview' | translate }}
</button>
<div *ngIf="training">
  <div class="title-section">
    <div>
      <img *ngIf="training.image && training.image.de"
           class="object-cover pb-3 w-full max-h-96"
           [src]="training.image | ct" alt="Training"/>
    </div>
  <h1 class="text-3xl font-bold">{{ training.title | ct }}</h1>
    <p *ngIf="training.price" class="text-black font-bold">
      {{ 'e-learning_training_price' | translate }} {{ (training.price / 100) | currency: 'EUR'}}
    </p>
    <br *ngIf="training.price" />
    <p class="text-gray-500">
      {{ training.intro | ct }}
    </p>
    <br />
    <p class="text-gray-500">
      {{ training.description | ct }}
    </p>
  </div>
  <br />
  <h3>{{ 'e-learning_chapter' | translate }}</h3>
  <app-list-chapter
    [training]="training"
    (firstChapterId)="setFirstChapterId($event)"
  ></app-list-chapter>
  <div class="bg-gray-50 px-4 py-3 text-right sm:px-6">
    <button
      *ngIf="purchased"
      type="button"
      mat-raised-button
      color="primary"
      (click)="startTraining()"
    >
      {{ 'e-learning_training_start' | translate }}
    </button>
    <button
      *ngIf="!purchased"
      [disabled]="loading"
      type="button"
      mat-raised-button
      color="primary"
      (click)="purchaseTraining()"
    >
      <div class="flex flex-row gap-4">
        <mat-spinner *ngIf="loading" [diameter]="20"></mat-spinner> {{'e-learning_training_purchase' | translate}}
      </div>
    </button>
  </div>

</div>
