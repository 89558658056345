<div
  class="flex min-h-full items-center justify-center px-4 sm:px-0 bg-blue-50"
>
  <div class="w-full max-w-lg">
    <!-- success error message -->
    <div class="mb-5">
      <div
        *ngIf="error"
        class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 relative"
        role="alert"
      >
        <p class="font-bold">Error</p>
        <p>{{ error }}</p>
        <mat-icon class="absolute top-2 right-2 cursor-pointer" (click)="closeModal()">close</mat-icon>
      </div>
      <div
        *ngIf="success"
        class="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 relative"
        role="alert"
      >
        <p class="font-bold">Success</p>
        <p>{{ success }}</p>
        <mat-icon class="absolute top-2 right-2 cursor-pointer" (click)="closeModal()">close</mat-icon>
      </div>
    </div>

    <!-- Forgot Password box -->
    <div class="rounded-xl shadow-lg dark:bg-black dark:border-black">
      <div class="p-4 sm:p-7">
        <div class="text-center">
          <h1 class="block text-2xl font-bold text-gray-800 dark:text-white">
            Forgot password?
          </h1>
          <p class="mt-2 text-sm text-gray-600 dark:text-gray-400">
            Remember your password?
            <a
              class="font-medium text-red-500 hover:text-red-300 hover:underline"
              routerLink="/login"
            >
              Login here
            </a>
          </p>
        </div>

        <div class="mt-5">
          <form>
            <div class="grid gap-y-4">
              <div>
                <label
                  for="email"
                  class="block text-sm font-bold ml-1 mb-2 dark:text-white"
                  >Email address</label
                >
                <div class="relative">
                  <input
                    [formControl]="emailFormControl"
                    type="email"
                    id="email"
                    name="email"
                    class="py-3 px-4 block w-full border-2 border-gray-200 rounded-md text-sm focus:border-red-500 focus:ring-red-500 shadow-sm"
                    aria-describedby="email-error"
                    required
                  />
                  <mat-error *ngIf="emailFormControl.invalid">
                    {{ getErrorMessage() }}
                  </mat-error>
                </div>
                <p class="hidden text-xs text-red-600 mt-2" id="email-error">
                  Please include a valid email address so we can get back to you
                </p>
              </div>
              <button
                type="submit"
                [disabled]="emailFormControl.invalid"
                class="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-red-500 text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-all dark:focus:ring-offset-gray-800 disabled:cursor-not-allowed disabled:hover:bg-red-400 disabled:bg-red-400"
                (click)="forgotPassword()"
              >
                Reset password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
