<div>
  <h2 class="text-2xl">{{content.title | ct}}</h2>
</div>

<div
  class="pt-4"
  *ngIf="content.intro.de">
  <h3>{{content.intro | ct}}</h3>
</div>

<div *ngIf="content?.type === 'image'">
  <div class="image-container my-5 gap-4"
    [class.md:grid]="content.imageType === 'above' || content.imageType === 'below'"
    [class.md:flex]="content.imageType === 'left' || content.imageType === 'right'"
  >
    <app-image [class.order-last]="content.imageType === 'below' || content.imageType === 'right'"
         [content]="content"></app-image>
    <span>{{ content.description | ct }}</span>
  </div>
</div>

<div *ngIf="content?.type === 'video'">
  <div class="pb-5">{{ content.description | ct }}</div>
  <div class="video-container my-5">
    <app-video
      [userVideo]="userVideo"
      [content]="content"
      (timeTrackingChanged)="onTimeTrackingChanged($event)"></app-video>
  </div>
</div>
<div *ngIf="content?.type === 'text'">
  <div class="pb-5">{{ content.description | ct }}</div>
  <app-text [content]="content"></app-text>
</div>
<div *ngIf="content?.type === 'quiz'">
  <div class="pb-5">{{ content.description | ct }}</div>
  <app-quizzes
    *ngIf="content?.type === 'quiz'"
    [contentId]="content!.id!"
    [trainingId]="trainingId"
    [chapterId]="chapterId"
    (quizCompleted)="onQuizCompleted($event)"
  ></app-quizzes>
</div>
