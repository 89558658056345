import {Component, Input} from '@angular/core';
import {Content} from "../../models/content.model";

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class TextComponent {

  @Input() content!: Content;

}
