<main *ngIf="loadingResults || loadingQuiz">
  <div class="rounded-lg border-4 border-dashed border-gray-200 p-12">
    <div class="flex items-center justify-center flex-col space-y-8">
      <mat-spinner
        [color]="'warn'">
      </mat-spinner>
      <span *ngIf="loadingResults">
        {{'e-learning_quiz_loading_results' | translate}}
      </span>
      <span *ngIf="loadingQuiz">
        {{'e-learning_quiz_loading_quiz' | translate}}
      </span>
    </div>
  </div>
</main>

<main *ngIf="quizzes.length > 0 && !loadingResults">
  <div class="mx-auto max-w-7xl py-6">
    <div class="px-4 py-6 sm:px-0" *ngFor="let quiz of quizzes">
      <div class="rounded-lg border-4 border-dashed border-gray-200"
        [class.border-red-600]="tries > 0 && !quiz.correct"
        [class.border-green-600]="tries > 0 && quiz.correct">
        <div class="px-6">
          <div class="py-6">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              {{ quiz.question | ct }}
            </h3>
          </div>
          <img *ngIf="quiz.image && quiz.image.de" [src]="quiz.image | ct">
          <video *ngIf="quiz.video && quiz.video.de" #videoPlayer
                 controls controlsList="nodownload" oncontextmenu="return false;">
            <source [src]="quiz.video | ct" type="video/mp4">
          </video>
        </div>
        <div>
          <!-- Start of Options  -->
          <app-single-choice
            *ngIf="quiz.type === 'single'"
            [options]="quiz.options"
            [isQuizCompleted]="isQuizCompleted"
            [disabled]="isExam && isExamCompleted"
            [tries]="tries"
            [correct]="quiz.correct || false"
            (selectOption)="onSingleChange(quiz)"
          ></app-single-choice>

          <app-multi-choice
            *ngIf="quiz.type === 'multi'"
            [options]="quiz.options"
            [isQuizCompleted]="isQuizCompleted"
            [disabled]="isExam && isExamCompleted"
            [tries]="tries"
            [correct]="quiz.correct || false"
            (selectOptions)="onMultiChange(quiz)"
          ></app-multi-choice>

          <!-- Fill in Blanks -->
          <app-blank-space
            *ngIf="quiz.type === 'fill'"
            [options]="quiz.options"
            [useFillOptions]="quiz.useFillOptions"
            [isQuizCompleted]="isQuizCompleted"
            [disabled]="isExam && isExamCompleted"
            [tries]="tries"
            [correct]="quiz.correct || false"
            (selectedOption)="onFillChange(quiz)"
          ></app-blank-space>

          <!-- Drag and Drop Sort -->
          <app-dnd-sort
            *ngIf="quiz.type === 'drag'"
            [left]="quiz.left!"
            [right]="quiz.right!"
            [isQuizCompleted]="isQuizCompleted"
            [disabled]="isExam && isExamCompleted"
            [tries]="tries"
            [correct]="quiz.correct || false"
            (selectionResult)="onDragChange(quiz)"
          ></app-dnd-sort>
          <!-- End of Options -->
        </div>
      </div>
    </div>
    <div *ngIf="isExam && isExamCompleted" class="flex flex-col space-y-4">
      <div *ngIf="userTraining?.badgeUrl">
        <img [src]="userTraining?.badgeUrl" class="w-48 mt-4" />
      </div>
      <span>
        Ihr Ergebnis: {{ userTraining?.score }}%
      </span>
      <span>
        Sie haben {{ userTraining?.reachedPoints }} Punkte von maximal möglichen {{ userTraining?.totalPoints }} Punkten erreicht.
      </span>

      <span *ngIf="userTraining?.passed">
        Sie haben die Schulung erfolgreich durchgeführt und die Prüfung bestanden.
      </span>

      <span *ngIf="!userTraining?.passed">
        Sie haben die Schulung durchgeführt, die Prüfung jedoch leider nicht bestanden.
      </span>

      <button
        *ngIf="userTraining?.passed"
        type="submit"
        mat-raised-button
        color="primary"
        (click)="downloadCertificate()"
      >
        {{ 'e-learning_training_certificate_download' | translate }}
      </button>
    </div>
    <button
      *ngIf="quizzes.length > 0 && !isExam"
      mat-raised-button
      color="primary"
      class="float-right"
      (click)="send()"
    >
      {{'e-learning_content_check_result' | translate}}
    </button>
    <button
      mat-raised-button
      color="primary"
      class="float-right"
      *ngIf="isExam && !isExamCompleted"
      (click)="finish()"
    >
      <span>{{ 'e-learning_content_send_exam' | translate }}</span>
    </button>
  </div>
</main>
