<div
  *ngIf="step; else icons"
  [class.bg-red-600]="completed"
  [class.text-white]="completed"
  [class.bg-white]="!completed"
  [class.text-black]="!completed || active"
  [class.bg-gray-300]="active"
  class="step-circle rounded-full text-center text-2xl transition duration-500 ease-in-out h-12 w-12 leading-none py-3 border-black border-2"
>
  {{ step | romanNumeral }}
</div>
<ng-template #icons>
  <div
    [class.bg-red-600]="completed"
    [class.text-white]="completed"
    [class.bg-white]="!completed"
    [class.text-black]="!completed || active"
    [class.bg-gray-300]="active"
    class="step-circle rounded-full text-center transition duration-500 ease-in-out h-20 w-20 border-black py-3 border-2"
  >
    <i
      [class.text-white]="!completed"
      [class.text-black]="!completed"
      [class]="'fas fa-' + icon + ' text-5xl'"></i>
  </div>
</ng-template>
<div
  *ngIf="false"
  class="absolute top-16 -ml-10 text-center w-32 text-xs font-medium uppercase text-red-600"
  [class]="icon ? 'top-20 -ml-6' : ''"
>
  <!-- According to the design it should only appear at the start and the end of the course progress. -->
  {{ point }} Punkte
</div>
