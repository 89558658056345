<mat-list role="list">
  <mat-list-item class="border-black border-solid border-b-[1px] !h-full !pb-4"
                 [class.!cursor-pointer]="(chapter.started || chapter.order === 1) && this.userTraining"
                 [style]="chapter.completedPercents! > 50 ?
                  'background: linear-gradient(to right, #d1d1d1 ' + chapter.completedPercents! + '%, white ' + (100 - chapter.completedPercents!) + '%);' :
                  'background: linear-gradient(to left, white ' + (100 - chapter.completedPercents!) + '%, #d1d1d1 ' + chapter.completedPercents + '%);'"
                 *ngFor="let chapter of chapters"
                 (click)="navigateTo(chapter)"
                 role="listitem">
    <div matListItemIcon
         class="!text-black font-bold"
    >
      {{chapter.order}}.
    </div>
    <div matListItemTitle
         class="!whitespace-normal !overflow-visible"
    >
      {{ chapter.title | ct }}
    </div>
    <div matListItemLine
      class="!whitespace-normal !overflow-visible"
    >{{chapter.intro | ct}}</div>
    <mat-icon
      class="!text-black !h-[40px]"
      *ngIf="chapter.completed" matListItemMeta>checked</mat-icon>
  </mat-list-item>
</mat-list>

<div *ngIf="userTraining?.completed" class="bg-gray-50 px-4 py-3 text-right sm:px-6">
  <button
    type="submit"
    mat-raised-button
    color="primary"
    (click)="downloadCertificate()"
  >
    {{ 'e-learning_training_certificate_download' | translate }}
  </button>
</div>

<mat-accordion *ngIf="false">
  <mat-expansion-panel
    *ngFor="let chapter of chapters"
    class="border-b border-black">
    <mat-expansion-panel-header class="h-full !py-2">
      <mat-panel-title class="!grow-0 font-bold">
        {{chapter.order}}.
      </mat-panel-title>
      <mat-panel-description class="justify-between items-center">
        <div><span class="text-black">{{chapter.title | ct}}</span><br />
          {{chapter.intro | ct}}
        </div>
        <mat-icon class="shrink-0">checked</mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>
    {{chapter.description | ct}}
  </mat-expansion-panel>
</mat-accordion>
