<div *ngIf="loading" class="loading-container w-fit">
  <div
    class="flex flex-col items-center justify-center h-screen w-screen bg-white"
  >
    <img src="../assets/img/SBS__LOGO__ELearning_1000.png" alt="Ladevorgang..." class="logo max-w-lg" />
    <h2 class="mt-5 text-2xl font-bold text-gray-700 loading">
      Wird geladen <span>.</span><span>.</span><span>.</span>
    </h2>
  </div>
</div>

<app-sidenav
  *ngIf="isAuthenticated && !loading"
  [userInfo]="currentUserData"
  [pageTitle]="pageTitle"
></app-sidenav>

<router-outlet *ngIf="!isAuthenticated && !loading"></router-outlet>
