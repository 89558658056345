import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {TrainingService} from "../../services/training.service";
import {Training} from "../../models/training.model";
import {UserTrainingService} from "../../../../shared/services/user-training.service";

@Component({
  selector: 'app-training-details',
  templateUrl: './training-details.component.html',
  styleUrls: ['./training-details.component.scss']
})
export class TrainingDetailsComponent implements OnInit, OnDestroy  {

  trainingId: string = '';
  private sub: any;
  training: Training | null = null;
  firstChapterId: string = '';
  loading: boolean = false;
  purchased: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private trainingsService: TrainingService,
    private userTrainingService: UserTrainingService
  ) {
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.trainingId = params['id'];
      if (this.trainingId) {
        this.getTraining();
      } else {
        this.router.navigate(['/trainings']).then();
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getTraining() {
    this.trainingsService.getTraining(this.trainingId).then(trainingSnap => {
      if (trainingSnap.exists()) {
        this.training = trainingSnap.data();
        this.userTrainingService.hasPurchasedTraining(this.trainingId).then(purchased => {
          this.purchased = purchased;
        });
      } else {
        this.router.navigate(['/trainings']).then();
      }
    });
  }

  setFirstChapterId(firstChapterId: string) {
    this.firstChapterId = firstChapterId;
  }

  startTraining() {
    this.userTrainingService.startTraining(this.trainingId, this.training?.title, this.training?.details?.totalTime || 0).then(() => {
      this.router.navigate(['/trainings', this.trainingId, 'chapter', this.firstChapterId, 'content', '1']).then();
    });
  }

  purchaseTraining() {
    this.loading = true;
    this.userTrainingService.purchaseTraining(this.trainingId).then(() => {
      console.log('purchaseSuccess');
    }).catch((error) => {
      console.error('purchaseError', error);
      this.loading = false;
    });
  }

}
