import { Injectable } from '@angular/core';
import {collection, doc, getDoc, getDocs, getFirestore, orderBy, query} from "@angular/fire/firestore";
import {chapterConverter} from "../models/chapter.model";
import {trainingConverter} from "../models/training.model";

@Injectable({
  providedIn: 'root'
})
export class ChapterService {

  db = getFirestore();

  constructor() { }

  getChapters(trainingId: string) {
    const chapterRef = collection(this.db, "trainings", trainingId, "chapters");
    const q = query(chapterRef.withConverter(chapterConverter), orderBy("order"));
    return getDocs(q);
  }

  getChapter(trainingId: string, chapterId: string) {
    const ref = doc(this.db, "trainings", trainingId, "chapters", chapterId);
    return getDoc(ref.withConverter(chapterConverter));
  }

  getParentTraining(trainingId: string) {
    const ref = doc(this.db, "trainings", trainingId);
    return getDoc(ref.withConverter(trainingConverter));
  }
}
