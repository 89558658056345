import {Component, ElementRef, Input, ViewChild, AfterViewInit, OnDestroy, Output, EventEmitter} from '@angular/core';
import {Content} from "../../models/content.model";
import {UserTrainingService} from "../../../../shared/services/user-training.service";

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements AfterViewInit {
  @Input() content!: Content;
  @Input() userVideo: any;
  @Output() timeTrackingChanged = new EventEmitter<any>;
  @ViewChild('videoPlayer') videoPlayer!: ElementRef<HTMLVideoElement>;
  @ViewChild('timeSlider') timeSlider!: ElementRef<HTMLInputElement>;

  timeTracking: any = {
    watchedTime: 0,
    currentTime: 0,
    completed: false
  };

  lastUpdated = new Date();

  ngAfterViewInit(): void {
    this.loadVideoPlaybackInfo();
    this.videoPlayer.nativeElement.addEventListener('timeupdate', this.updateTime.bind(this));
    this.videoPlayer.nativeElement.addEventListener('seeking', this.preventSeeking.bind(this));
    this.videoPlayer.nativeElement.addEventListener('ended', this.ended.bind(this));
  }

  updateTime() {
    const video = this.videoPlayer.nativeElement;
    if (!video.seeking) {
      if (video.currentTime > this.timeTracking.watchedTime) {
        this.timeTracking.watchedTime = video.currentTime;
      } else {
        this.timeTracking.currentTime = video.currentTime;
      }
    }
    if (this.lastUpdated.getTime() + 5000 < new Date().getTime()) {
      this.lastUpdated = new Date();
      this.timeTrackingChanged.emit(this.timeTracking);
    }
  }

  loadVideoPlaybackInfo() {
    console.log('this.userVideo');
    console.log(this.userVideo);
    if (this.userVideo) {
      this.timeTracking = this.userVideo;
      this.videoPlayer.nativeElement.currentTime = this.timeTracking.watchedTime || false;
    }
  }

  preventSeeking() {
    // guard against infinite recursion:
    // user seeks, seeking is fired, currentTime is modified, seeking is fired, current time is modified, ....
    const video = this.videoPlayer.nativeElement;
    const delta = video.currentTime - this.timeTracking.watchedTime;

    if (delta > 0) {
      video.pause();
      video.currentTime = this.timeTracking.watchedTime;
      // video.play().then();
    }
  }

  ended() {
    this.timeTracking.completed = true;
    this.timeTrackingChanged.emit(this.timeTracking);
  }
}
