<div *ngIf="!correct && tries == 1"  class="text-red-700 pl-8 mt-8">
  {{'e-learning_quiz_not_correct_hint' | translate}}
</div>
<div class="m-6">
  <!-- ---------------------- Static Drag and Drop Sort ---------------------- -->
  <div class="dnd-sort-container pr-2">
    <div class="dnd-sort-list">
      <div
        class="dnd-sort-box text-lg font-medium"
        *ngFor="let ol of left"
        cdkDrag
        [cdkDragDisabled]="true"
        [class.error]="isQuizCompleted && !ol.result && tries > 1"
        [class.success]="isQuizCompleted && ol.result && (tries > 1 || correct)"
      >
        {{ ol.name | ct }}
      </div>
    </div>
  </div>

  <!-- ------------------------ TO Drag and Drop Sort ------------------------ -->
  <div class="dnd-sort-container pl-2">
    <div cdkDropList class="dnd-sort-list" (cdkDropListDropped)="drop($event)">
      <div
        class="dnd-sort-box text-lg font-medium"
        *ngFor="let or of right"
        cdkDrag
        [cdkDragData]="or"
        (cdkDragDropped)="dragEnded($event)"
        [cdkDragDisabled]="disabled"
        [class.error]="isQuizCompleted && !or.result && tries > 1"
        [class.success]="isQuizCompleted && or.result && (tries > 1 || correct)"
      >
        {{ or.answer | ct }}
      </div>
    </div>
  </div>
</div>
