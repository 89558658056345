import { Component } from '@angular/core';
import { take } from 'rxjs';
import { User } from '../../models/user.model';
import {AuthenticationService} from "../../../../core/services/auth/authentication.service";
import {UserTrainingService} from "../../../../shared/services/user-training.service";
import {UserTraining} from "../../../../shared/models/user-training.model";


@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss']
})
export class ProfilePageComponent {

  currentUser: any;
  userInfo!: User;
  uid: any;
  photoURL!: string;
  isAdmin!: boolean;
  userTrainings: UserTraining[] = [];
  passedTrainingsWithBadges: UserTraining[] = [];

  constructor(
    private readonly authService: AuthenticationService,
    private userTrainingService: UserTrainingService
  ) { }

  ngOnInit(): void {
    this.currentUser = this.authService.getCurrentUser().subscribe((user) => {
      if (user) {
        this.authService.getUserData(user.uid).pipe(take(1)).subscribe((data: any) => {
          this.userInfo = data;
          this.photoURL = data.photoURL;
          this.isAdmin = false;
        });
        this.userTrainingService.getUserTrainings().then((userTrainings: UserTraining[]) => {
          this.userTrainings = userTrainings;
          this.passedTrainingsWithBadges = userTrainings.filter((userTraining: UserTraining) => {
            return userTraining.completed && userTraining.passed && userTraining.badgeUrl;
          });
        });
      }
    }
    );
  }

  ngOnDestroy(): void {
    this.currentUser.unsubscribe();
  }
}
