<div class="container h-full">
  <div
    class="card cursor-pointer border-2 border-black hover:shadow-md hover:border-opacity-75 transform hover:-translate-y-1 transition-all duration-200 antialiased h-full"
  >
    <div class="m-3">
      <h2 class="text-lg mb-2">
        {{ trainingName | ct }}
      </h2>

      <div class="w-full bg-gray-200 h-3 mb-6 border-solid border border-black">
        <div
          class="bg-red-600 h-full"
          [style.width]="progressPercent <= 100 ? progressPercent + '%' : '100%'"
        ></div>
      </div>

      <p
        class="font-light font-mono text-sm text-gray-700 hover:text-gray-900 transition-all duration-200"
      >
        <span class="">{{ progressPercent <= 100 ? progressPercent : '100' }}% {{ "completed" }}</span>
      </p>
    </div>
  </div>
</div>
