<div>
  <app-personal-info-form
    *ngIf="userInfo"
    [userInfo]="userInfo"
    [photoURL]="photoURL"
    (onFormGroupChange)="onFormGroupChangeEvent($event)"
  ></app-personal-info-form>

  <div class="hidden sm:block" aria-hidden="true">
    <div class="py-5">
      <div class="border-t border-gray-200"></div>
    </div>
  </div>

  <div class="mt-10 sm:mt-0">
    <div class="md:grid md:grid-cols-3 md:gap-6">
      <div class="md:col-span-1">
        <div class="px-4 sm:px-0">
          <h3 class="text-lg font-medium leading-6 text-gray-900">
            Company Information
          </h3>
          <p class="mt-1 text-sm text-gray-600">
            Here you can find company information
          </p>
        </div>
      </div>
      <div class="mt-5 md:col-span-2 md:mt-0">
        <div class="overflow-hidden shadow sm:rounded-md">
          <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
            <div class="grid grid-cols-6 gap-6">
              <div class="col-span-6 lg:col-span-3">
                <label
                  for="companyName"
                  class="block text-sm font-medium text-gray-700"
                  >Group</label
                >
                <input
                  name="companyName"
                  id="companyName"
                  value="TestADm"
                  class="bg-black text-center text-white"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="hidden sm:block" aria-hidden="true">
    <div class="py-5">
      <div class="border-t border-gray-200"></div>
    </div>
  </div>

  <div class="bg-gray-50 px-4 py-3 text-right sm:px-6">
    <button
      type="submit"
      class="inline-flex justify-center rounded-md border border-transparent bg-black bg-opacity-80 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-black focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2"
      (click)="updateProfile(updatedData)"
      >
      Save
    </button>
  </div>
</div>
