import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Pipe({
  name: 'ct'
})
export class ContentTranslatePipe implements PipeTransform {

  constructor(
    private translateService: TranslateService
  ) {
  }
  transform(value: any, marker?: any): any {
    if (!value) {
      return '';
    }
    let text = value[this.translateService.currentLang] || value.de;
    if (marker) {
      const markers = marker[this.translateService.currentLang] || marker.de;
      markers.split(',').forEach((field: string) => {
        const find = field.trim();
        const replace = '<span class="mark">' + field.trim() + '</span>';
        const regex = new RegExp(find, 'gi');
        text = text.replace(regex, replace);
      });
      return text;
    } else {
      return text;
    }
  }

}
