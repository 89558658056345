import { Component, Input } from '@angular/core';
import { take } from 'rxjs';
import { User } from '../../models/user.model';
import { UserService } from '../../service/user.service';
import {AuthenticationService} from "../../../../core/services/auth/authentication.service";

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.scss']
})
export class ProfileEditComponent {

  @Input() userInfo!: User;


  firstName: string = '';
  lastName: string = '';
  photoURL: string = '';
  email: string = '';
  phone: string = '';
  personnelNumber: string = '';
  companyName: string = '';
  isTrainer: boolean = false;
  isAdmin: boolean = false;


  currentUser: any;

  updatedData: Partial<User> = {};
  formCheck: any;

  public onFormGroupChangeEvent(_event: any) {
    this.formCheck = _event;
    this.formCheck.valueChanges.subscribe((data: any) => {
      this.updatedData = data;
    });
  }


  constructor(
    private authService: AuthenticationService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.currentUser = this.authService.getCurrentUser().subscribe(async (user) => {
      if (user) {
        (this.authService.getUserData(user.uid)).pipe(take(1)).subscribe((data: any) => {
          this.userInfo = {
            ...data,
            uid: user.uid
          };

          this.photoURL = data.photoURL;
          this.setCurrentUserInformation(data);
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.currentUser.unsubscribe();
    this.formCheck.valueChanges.unsubscribe();
  }

  setCurrentUserInformation(data: any) {
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.photoURL = data.photoURL;
    this.email = data.email;
    this.phone = data.phone;
    this.personnelNumber = data.personnelNumber;
    this.companyName = data.companyName;
    this.isTrainer = data.isTrainer;
    this.isAdmin = data.isAdmin;
  }

  async updateProfile(user: Partial<User>) {
    await this.userService.updateUser({ ...this.userInfo, ...user });
  }

}
