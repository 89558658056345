<div
  class="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8"
>
  <div class="w-full max-w-md">
    <div>
      <img
        class="mx-auto h-24 w-auto mb-20"
        src="../../../../assets/img/SBS__LOGO__ELearning.png"
        alt="SBS Logo"
      />
      <h2
        class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900"
      >
        {{ 'e-learning_training_login_title' | translate }}
      </h2>
    </div>
    <form class="mt-8 space-y-6">
      <!-- <input type="hidden" name="remember" value="true" /> -->
      <div
        *ngIf="accountErrorMessage"
        class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4"
        role="alert"
      >
        <p class="font-bold">{{ 'e-learning_training_login_error' | translate }}</p>
        <p>{{ accountErrorMessage }}</p>
      </div>
      <div class="rounded-md shadow-sm">
        <div>
          <label for="email-address" class="sr-only">{{ 'e-learning_training_login_mail' | translate }}</label>
          <input
            [formControl]="emailFormControl"
            id="email-address"
            name="email"
            type="email"
            placeholder="{{ 'e-learning_training_login_mail' | translate }}"
            class="relative block w-full appearance-none rounded-none rounded-t-md border
            focus:outline-none
            border-{{
              emailFormControl.invalid ? 'red' : 'gray'
            }}-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-{{
              emailFormControl.invalid ? 'red' : 'indigo'
            }}-500
            focus:ring-{{
              emailFormControl.invalid ? 'red' : 'indigo'
            }}-500 sm:text-sm"
            required
          />
          <mat-error *ngIf="emailFormControl.invalid">
            {{ getErrorMessage() }}
          </mat-error>
        </div>
        <div class="mt-2">
          <label for="password" class="sr-only">{{ 'e-learning_training_password' | translate }}</label>
          <div class="flex justify-end items-center relative">
            <input
              [formControl]="passwordFormControl"
              id="password"
              name="password"
              [type]="hide ? 'password' : 'text'"
              placeholder="{{ 'e-learning_training_password' | translate }}"
              class="group relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              required
            />
            <button
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide"
              (click)="hide = !hide"
              class="absolute inset-y-0 right-2 flex items-center pl-3"
            >
              <mat-icon>
                {{ hide ? "visibility_off" : "visibility" }}
              </mat-icon>
            </button>
          </div>
        </div>
      </div>

      <!-- Button -->
      <div>
        <button
          class="group relative flex w-full justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 disabled:bg-red-300 disabled:cursor-not-allowed"
          [disabled]="emailFormControl.invalid"
          (click)="login()"
        >
          <span class="absolute inset-y-0 left-0 flex items-center pl-3">
            <mat-icon> lock </mat-icon>
          </span>
          {{ 'e-learning_training_login' | translate }}
        </button>
      </div>
    </form>

    <!-- Forgot Password -->
    <div class="mt-3">
      <a
        routerLink="/forgot-password"
        class="font-medium text-red-700 hover:text-red-500"
      >
        {{ 'e-learning_training_forgot_password_link' | translate }}
      </a>
    </div>

    <!-- Register -->
    <div class="mt-3">
      <a
        routerLink="/registration"
        class="font-medium text-red-700 hover:text-red-500"
      >
        {{ 'e-learning_training_registration_link' | translate }}
      </a>
    </div>
  </div>
</div>
