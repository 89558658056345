<button
  [routerLink]="link"
  class="mt-5 text-white hover:text-gray-100 hover:bg-gray-700 focus:outline-none
  focus:text-gray-100 w-full py-2 text-lg font-normal text-left pl-2"
  [routerLinkActive]="isAdmin ? 'bg-red-500 focus:bg-red-500' : 'bg-black focus:bg-black'"
  [routerLinkActiveOptions]="{ exact: exact }"
>
  <mat-icon class="mr-2 text-lg align-top">{{ icon }}</mat-icon>
  {{ name }}
</button>
