<div *ngIf="!correct && tries == 1"  class="text-red-700 pl-8 mt-8">
  {{'e-learning_quiz_not_correct_hint' | translate}}
</div>
<div *ngIf="finishedInit">
  <div
    class="m-6 text-gray-700 text-lg font-medium"
    *ngFor="let option of options; let i = index"
  >
    <div [class]="isQuizCompleted ? (option.result ? 'bg-green-600 text-white' : 'bg-red-600 text-white') : ''"
         [innerHTML]="(option.fillText | ct) | safe"></div>
    <div *ngIf="!option.result && isQuizCompleted && tries > 1" class="bg-green-600 text-white">
      {{ (option.question | ct)}}
    </div>
    <div *ngIf="option.hint && isQuizCompleted && tries > 1" class="bg-white text-gray-700">
      {{'hint' | translate}}: {{ (option.hint)}}
    </div>
  </div>
</div>
