import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UserInfo } from 'firebase/auth';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private firestore: AngularFirestore,
  ) { }

  updateUser(user: UserInfo) {
    return this.firestore.collection('users').doc(user.uid).update({ ...user });
  }
}
